import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DeviceCommandService {

  constructor(
    private http: HttpClient
  ) { }


  list(page, row, searchType) {
    return this.http.get(`${environment.apiBaseUrl}v2/devicecommands/list?page=${page}&row=${row}&searchType=${searchType}`)
      .pipe(
        map(res => res['deviceCommands'])
      ).toPromise();
  }
  getTeltonikaCommands() {
    return this.http.get(`${environment.apiBaseUrl}v2/devicecommands/teltonikacommandlist`)
      .pipe(
        map(res => res['teltonikaCommands'])
      ).toPromise();
  }
  addDeviceCommand(CommandModel) {
    return this.http.post(`${environment.apiBaseUrl}v2/devicecommands/save`,
      CommandModel
    ).toPromise();
  }
  delete(commandId) {
    return this.http.get(`${environment.apiBaseUrl}v2/devicecommands/delete?ID=${commandId}`)
      .pipe(
        map(res => res)
      )
      .toPromise();
  }
  bulkSave(CommandModel) {
    return this.http.post(`${environment.apiBaseUrl}v2/devicecommands/bulkSave`,
      CommandModel
    ).toPromise();
  }
  bulkList(page, row) {
    return this.http.get(`${environment.apiBaseUrl}v2/devicecommands/bulkList?page=${page}&row=${row}`)
      .pipe(
        map(res => res['Data'])
      ).toPromise();
  }
  bulkUpdate(ID) {
    return this.http.get(`${environment.apiBaseUrl}v2/devicecommands/bulkUpdate?ID=${ID}`)
      .toPromise();
  }

  bulkDelete(ID) {
    return this.http.get(`${environment.apiBaseUrl}v2/devicecommands/bulkDelete?ID=${ID}`)
      .toPromise();
  }
}


