import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from './../../environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(private http: HttpClient) { }

  add(device) {

    return this.http.post(`${environment.apiBaseUrl}v2/devices/save`, device).toPromise();
  }

  list() {
    return this.http.get(`${environment.apiBaseUrl}v2/devices/list`)
      .pipe(
        map(res => res['data'])
      )
      .toPromise();
  }

  /**
    * @description Returns all the devices with their
    * corresponding peripherals
    * @todo Currently ruptella is hardcoded, must replace that to
    * return all the devices
    */
  getDevices() {
    const apiUrl = environment.apiBaseUrl + 'v2/peripherals/list';
    return this.http.get(apiUrl).pipe(
      map(res => res['data'])
    ).toPromise();
  }
}
